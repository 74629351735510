import React from "react";
import HeaderMenuBS from "../containers/Corporate2/HeaderMenuBS";
import Banner from "../containers/Corporate2/Banner";
import Footer from "../containers/Corporate2/Footer";
import KeyServices from "../containers/Corporate2/KeyServices";
import About from "../containers/Corporate2/About";
import Contact from "../containers/Corporate2/Contact";
import Services from "../containers/Corporate2/Services";
import Team from "../containers/Corporate2/Team";
import Portfolio from "../containers/Corporate2/Portfolio";
import SubscribeLetter from "../containers/Corporate2/SubscribeLetter";
import GlobalStyle from "../containers/Corporate2/Common/global-styles";
import Testimonials from "../containers/Corporate2/Testimonials";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/Corporate2/Common/fonts"
import BuyNow from "../components/BuyNow";

const Corporate = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenuBS/>
       <main>
       <Seo 
                title="Corporate 2 ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy Corporate 2 Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <KeyServices/>
            <About/>
            <Services/>
            <Portfolio/>
            <Team/>
            <Testimonials/>
            <SubscribeLetter/>
            <Contact/>
        </main>
       <Footer/>
   </div>

)

export default Corporate;
