import React from 'react';
import {KeyServicesSection,KeyServicesLeft,KeyServicesRight,KeyServicesCard,
KeyServicesCardImg,
KeyServicesLeftBtn,KeyServicesRow,KeyServicesCardtopMargin
} from './keyservices.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const KeyServices = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            corporate2Json {
                KeyServices {
                    DelayConstant
                    KeyServicesLeft{
                        KeyServicesLeftH3
                        KeyServicesLeftPara
                        KeyServicesLeftBtn
                    }
                    KeyServicesRight1{
                        KeyServicesCardIcon
                        KeyServicesCardHeading
                        KeyServicesCardPara
                    }
                    KeyServicesRight2{
                        KeyServicesCardIcon
                        KeyServicesCardHeading
                        KeyServicesCardPara
                    }

                }
            }
        }
    `);

    return (
        <KeyServicesSection id="KeyServicesContainer">
            <Container>
                <KeyServicesRow>
                    <Col md="12" lg="4">
                        <Fade delay={1*JSONData.corporate2Json.KeyServices.DelayConstant}>
                            <KeyServicesLeft>
                                <h2>{JSONData.corporate2Json.KeyServices.KeyServicesLeft.KeyServicesLeftH3}</h2>
                                <p>{JSONData.corporate2Json.KeyServices.KeyServicesLeft.KeyServicesLeftPara}</p>
                                <KeyServicesLeftBtn href="#">{JSONData.corporate2Json.KeyServices.KeyServicesLeft.KeyServicesLeftBtn}</KeyServicesLeftBtn>
                            </KeyServicesLeft>
                        </Fade>
                    </Col>
                    <Col md="12" lg="8">
                        <KeyServicesRight>
                            <Row>
                                <Col lg="6" md="6" sm="12">
                                    <KeyServicesCardtopMargin>
                                        {
                                            JSONData.corporate2Json.KeyServices.KeyServicesRight1.map((item,idx) => {
                                            return  <Fade delay={(idx+1)*JSONData.corporate2Json.KeyServices.DelayConstant}>
                                                <KeyServicesCard>
                                                    <KeyServicesCardImg>
                                                        <img src={item.KeyServicesCardIcon} alt=""/>
                                                    </KeyServicesCardImg>
                                                    <h5>{item.KeyServicesCardHeading}</h5>
                                                    <p>{item.KeyServicesCardPara}</p>
                                                </KeyServicesCard>
                                            </Fade>
                                            })
                                        }
                                    </KeyServicesCardtopMargin>
                                </Col>
                                <Col lg="6" md="6" sm="12">
                                    {
                                        JSONData.corporate2Json.KeyServices.KeyServicesRight2.map((item,idx) => {
                                        return <Fade delay={(idx+1)*JSONData.corporate2Json.KeyServices.DelayConstant}>
                                                <KeyServicesCard>
                                                    <KeyServicesCardImg>
                                                        <img src={item.KeyServicesCardIcon} alt=""/>
                                                    </KeyServicesCardImg>
                                                    <h5>{item.KeyServicesCardHeading}</h5>
                                                    <p>{item.KeyServicesCardPara}</p>
                                                </KeyServicesCard>
                                            </Fade>
                                        })
                                    }
                                </Col>
                            </Row>
                        </KeyServicesRight>
                    </Col>
                </KeyServicesRow>
            </Container>
        </KeyServicesSection>

    );
}

export default KeyServices;
