import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FooterCustomContainerfluid,FooterSection,FooterCol,
    FooterLi,FooterSocial,BottomFooterPara,BottomLink,FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,
    BottomFooterWrapper} from './footer.style';

import Fade from "react-reveal/Fade"

import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            corporate2Json {
                Footer {
                    DelayConstant
                    FooterCol1{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol2{
                        FooterTitle
                        FooterPara{
                            Para
                        }
                    }
                    FooterCol3{
                        FooterTitle
                        Items
                        FooterUl{
                            Menu
                            Href
                        }
                    }
                    FooterCol4{
                        FooterTitle
                        FooterSocial{
                            FooterSocialImg
                        }
                    }
                    BottomFooterPara
                    BottomFooterName
                    BottomFooterLink
                }
            }
        }
    `);
        return (
        <div>
            <FooterCustomContainerfluid>
                <FooterSection>
                    <Container>
                        <Fade delay={JSONData.corporate2Json.Footer.DelayConstant}>
                            <Row>
                                <Col md="3">
                                    <FooterCol>
                                        <h4>{JSONData.corporate2Json.Footer.FooterCol1.FooterTitle}</h4>
                                        {
                                            JSONData.corporate2Json.Footer.FooterCol1.FooterPara.map((item,idx) => {
                                            return <p>
                                                {item.Para}
                                                </p>
                                            })
                                        }
                                    </FooterCol>
                                    </Col>
                                    <Col md="3">
                                    <FooterCol>
                                        <h4>{JSONData.corporate2Json.Footer.FooterCol2.FooterTitle}</h4>
                                        {
                                            JSONData.corporate2Json.Footer.FooterCol2.FooterPara.map((item,idx) => {
                                            return <p>
                                                {item.Para}
                                                </p>
                                            })
                                        }
                                    </FooterCol>
                                    </Col>
                                    <Col md="3">
                                        <FooterCol>
                                        <h4>{JSONData.corporate2Json.Footer.FooterCol3.FooterTitle}</h4>
                                        <ScrollSpy offset={-59} items={JSONData.corporate2Json.Footer.Items} currentClassName="is-current">
                                        {
                                            JSONData.corporate2Json.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                            return <FooterLi>
                                                <AnchorLink offset={55} href={item.Href}>
                                                    {item.Menu}
                                                </AnchorLink>
                                            </FooterLi>
                                            })
                                        }
                                        </ScrollSpy>
                                        </FooterCol>
                                    </Col>
                                    <Col md="3">
                                        <FooterCol>
                                            <h4>{JSONData.corporate2Json.Footer.FooterCol4.FooterTitle}</h4>
                                            <FooterSocial href="#" aria-label="Footer Link">
                                                <FbIcon/>
                                            </FooterSocial>
                                            <FooterSocial href="#" aria-label="Footer Link">
                                                <TwitterIcon/>
                                            </FooterSocial>
                                            <FooterSocial href="#" aria-label="Footer Link">
                                                <LinkedinIcon/>
                                            </FooterSocial>
                                            <FooterSocial href="#" aria-label="Footer Link">
                                                <InstaIcon/>
                                            </FooterSocial>
                                        </FooterCol>
                                    </Col>
                            </Row>
                        </Fade>
                    </Container>
                </FooterSection>
                <BottomFooterWrapper>
                    <Container>
                        <Fade delay={JSONData.corporate2Json.Footer.DelayConstant}>
                            <Row>
                                <Col md="12">
                                    <BottomFooterPara>
                                        {JSONData.corporate2Json.Footer.BottomFooterPara}
                                        <BottomLink rel="noreferrer" href={JSONData.corporate2Json.Footer.BottomFooterLink} target="_blank">
                                        {JSONData.corporate2Json.Footer.BottomFooterName}
                                        </BottomLink>
                                    </BottomFooterPara>
                                </Col>
                            </Row>
                        </Fade>
                    </Container>
                </BottomFooterWrapper>
            </FooterCustomContainerfluid>
        </div>
    );
}

export default Footer;
