import React from 'react';
import {AboutSection,AboutRight,AboutLeft,AboutCardImg,AboutRightBtn,AboutRow
} from './about.style';
import {Container, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import Fade from "react-reveal/Fade";
import { useStaticQuery, graphql } from 'gatsby';

const ImageText = () => {
    const JSONData  = useStaticQuery(graphql`{
  corporate2Json {
    ImageText {
      DelayConstant
      AboutCardIcon {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      AboutRight {
        AboutRightH3
        AboutRightPara
        AboutRightBtn
      }
    }
  }
}
`);
    return (
        <AboutSection id="imageTextContainer">
            <Container>
                <AboutRow>
                    <Col md="12" lg="7">
                        <AboutLeft>
                            <Fade delay={1*JSONData.corporate2Json.ImageText.DelayConstant}>
                                <AboutCardImg>
                                    <GatsbyImage
                                        image={JSONData.corporate2Json.ImageText.AboutCardIcon.childImageSharp.gatsbyImageData}
                                        alt="" />
                                </AboutCardImg>
                            </Fade>
                        </AboutLeft>
                    </Col>
                    <Col md="12" lg="5">
                        <AboutRight>
                            <Fade delay={1*JSONData.corporate2Json.ImageText.DelayConstant}>
                                <h2>{JSONData.corporate2Json.ImageText.AboutRight.AboutRightH3}</h2>
                                <p>{JSONData.corporate2Json.ImageText.AboutRight.AboutRightPara}</p>
                                <AboutRightBtn href="#">{JSONData.corporate2Json.ImageText.AboutRight.AboutRightBtn}</AboutRightBtn>
                            </Fade>
                        </AboutRight>
                    </Col>
                </AboutRow>
            </Container>
        </AboutSection>
    );
}

export default ImageText;
