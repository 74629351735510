import {createGlobalStyle} from 'styled-components';

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Source Sans Pro', sans-serif;
    }
    h1,h2,h3,h4,h5,h6{
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        margin-bottom:20px;
        color:#3a3a3a;
    }
    h1{
        font-size:55px;
        line-height:61px;
    }
    h2{
        font-size:36px;
        line-height:42px;
    }
    h3{
        font-size:30px;
        line-height:36px;
    }
    h4{
        font-size:26px;
        line-height:32px;
    }
    h5{
        font-size:24px;
        line-height:30px;
    }
    h6{
        font-size:18px;
        line-height:26px;
    }
    p{
        line-height:1.7;
        font-size:14px;
        color:#7a7f83;
    }
    a{
        font-size:16px;
        text-decoration:none;
        :hover{
            text-decoration:none;
        }
    }
    form{
        margin-bottom:0px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
